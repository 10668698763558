.clickable {
    cursor: pointer;
}
.red {
    color: red
}
.green {
    color: green;
}
@keyframes pulsate {
    0% { opacity: 50%; }
    50% { opacity: 100%; }
    100% { opacity: 50%; }
}
.pulsate {
    opacity: 50%;
    animation: pulsate 2s ease-out 0s infinite;
    -webkit-animation: pulsate 2s ease-out 0s infinite;
    -moz-animation: pulsate 2s ease-out 0s infinite;
}